
import { Component, Vue } from "vue-property-decorator";
import { appId } from "@/../capacitor.config.json";
import moment, { Moment } from "moment";

type Credentials = {
  login: string;
  password: string;
};

type UserData = {
  bundle?: string;
  user: object;
  person: object;
  profile: {
    birthday?: string;
  };
};

@Component({
  components: {
    Alert: () => import("@/app/modules/_global/components/Alert.vue"),
    RegisterForm: () => import("@/app/modules/auth/components/RegisterForm.vue")
  }
})
export default class RegisterPage extends Vue {
  userData: UserData = {
    user: {},
    person: {},
    profile: {}
  };

  acceptTerms: boolean = false as boolean;
  disclaimerDialog: boolean = false as boolean;
  errors = null;

  get appData() {
    return this.$store.getters["app/data"];
  }

  get appLayout() {
    try {
      return this.appData.layout;
    } catch (e) {
      return null;
    }
  }

  created() {
    if (this.$route.params.source == "guest") {
      this.$store.commit("auth/destroyApiToken");
    }
  }

  register() {
    let birthday: Moment | string = moment(
      this.userData.profile.birthday,
      "DD.MM.YYYY"
    );

    if (birthday.isValid()) birthday = birthday.format("YYYY-MM-DD");
    else birthday = "";

    this.userData = {
      bundle: appId,
      user: { ...this.userData.user },
      person: { ...this.userData.person },
      profile: {
        ...this.userData.profile,
        birthday
      }
    };

    this.$store
      .dispatch("auth/register", this.userData)
      .then(() => this.welcome())
      .catch(error => {
        this.errors = error.response.data.errors;
      });
  }

  welcome() {
    this.$router.push({ name: "welcome" });
  }
}
